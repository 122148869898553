


















import Vue from "vue";

import ClubTitleLayout from "../components/Editor/Functional/ClubTitleLayout.vue";
import InformationLayout from "../components/Editor/Functional/InformationLayout.vue";
import MembersLayout from "../components/Editor/Functional/MembersLayout.vue";
import ApplyButtonLayout from "../components/Editor/Functional/ApplyButtonLayout.vue";

export default Vue.extend({
	components: {
		ClubTitleLayout,
		InformationLayout,
		MembersLayout,
		ApplyButtonLayout
	},
	data() {
		return {
			componentList: [] as any,
			isLoading: false
		};
	},
	created() {
		this.$store.commit("pushLoading", {
			name: "GET_CLUB_PAGE",
			message: "동아리 페이지 불러오는 중"
		});
		this.isLoading = true;
		this.$store
			.dispatch("GET_CLUB", this.$route.params.club)
			.then(club => {
				if (!club) this.$router.push("/");
				this.componentList = club.page;
				this.isLoading = false;
				this.$store.commit("clearLoading", "GET_CLUB_PAGE");
			})
			.catch(err => {
				this.isLoading = false;
				this.$store.commit("clearLoading", "GET_CLUB_PAGE");
				this.$router.push("/");
			});
	},
	computed: {
		getComponentList(): any[] {
			return this.$store.state.club.page;
		}
	}
});
